import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	AddCircleOutline,
	CheckBox,
	CheckBoxOutlineBlank,
	ImageOutlined,
	MoreHorizOutlined,
	OndemandVideoOutlined,
	PlaylistPlayOutlined,
} from '@material-ui/icons';

import { getDetails, getHighQThumbnailFromRenditions } from '../../services/mediaDisplayService';
import { customScreenType, mediaActionKeys, mediaNames, mediaTypes } from '../../constants/mediaConstants';
import { ReactComponent as Interact } from '../../assets/svg/InteractiveProject.svg';
import no_thumbnail from '../../assets/svg/NoThumbnail.svg';
import { ReactComponent as AdobeExpressIcon } from '../../assets/images/adobe_express.svg';
import { convertSecondsToTimeStamp, dateToTimeElapsed } from '../../services/timeStampService';
import { joinClassNames } from '../../services/elementHelperService';
import { getInspectLink, getMediaTypeText, isMediaProcessing } from '../../utils/commonUtil';
import { setSelectedCatalogId } from '../../actions/publishActions';

import { MediaItemProps, MediasDisplayProps, ShortcutMenuItems } from './MediasDisplay';
import { EDITED_MEDIA_ID_PREFIX } from '../../screens/PublishScreen/AdobeExpress/utils';

import './GridMediaDisplay.scss';
import { notAllowToShowEditCatalogAccess } from '../MediaLibrary/utils';

export const GridMediasDisplay = ({
	medias,
	mediaType,
	selectedMedias,
	isMiniLibrary,
	thumbnails,
	isAllowAEOption,
	classNames,
	screenType,
	shortcutLabel,
	emptyContainer,
	restrictions,
	onShortcutActionClick,
	onSelectMedia,
	onMediaActionMenuClick,
	onLibraryViewChange,
	onMediaItemClick,
}: MediasDisplayProps) => {
	const isMobileView = useMemo(
		() => screenType === customScreenType.small || screenType === customScreenType.mobile,
		[screenType]
	);

	return (
		<div
			className={joinClassNames(
				'grid-media-display',
				!medias || medias.filter(Boolean).length === 0 ? 'grid-media-display--empty' : '',
				classNames
			)}
		>
			{onShortcutActionClick && <MediaAction onActionClick={onShortcutActionClick} actionLabel={shortcutLabel} />}
			{medias && medias.filter(Boolean).length > 0
				? medias.map((media: any) => (
						<MediaContainer
							restrictions={restrictions}
							key={media.id}
							media={media}
							mediaType={mediaType}
							onSelectMedia={onSelectMedia}
							onMediaActionMenuClick={onMediaActionMenuClick}
							isSelected={(selectedMedias ?? []).find((i: any) => i.id === media.id)}
							isMiniLibrary={isMiniLibrary}
							onLibraryViewChange={onLibraryViewChange}
							thumbnail={(thumbnails ?? []).filter(Boolean).find((t: any) => t.scenarioId === media.id)}
							isAllowAEOption={isAllowAEOption}
							onMediaItemClick={onMediaItemClick}
							isMobileView={isMobileView}
						/>
				  ))
				: emptyContainer}
		</div>
	);
};

const mediaContainerBaseClassName = 'media-container';

export const MediaContainer: React.FC<MediaItemProps> = ({
	media,
	mediaType,
	isSelected,
	isMiniLibrary,
	thumbnail: thumbnailMedia,
	isMobileView,
	isAllowAEOption,
	restrictions,
	onSelectMedia,
	onMediaActionMenuClick,
	onLibraryViewChange,
	onMediaItemClick: onMediaItemClickProps,
}) => {
	const dispatch = useDispatch() as any;
	const { t } = useTranslation();
	const accountRestrictions = useSelector((state) => (state as any).publish.accountRestrictions);
	const { userId, userPermissions } = useSelector((state) => (state as any).session);
	const { catalogs } = useSelector((state) => (state as any).publish);
	const [contextMenu, setContextMenu] = useState<any | undefined>(undefined);

	const mediaDetail = getDetails(media, mediaType === mediaTypes.playlists);

	const thumbnail = useMemo(() => {
		let thumbnailSource = mediaDetail;
		if (thumbnailMedia) {
			thumbnailSource = getDetails(thumbnailMedia, false);
		}

		if (!thumbnailSource) {
			return '';
		}

		let mediaThumbnail = thumbnailSource.thumbnail as any;
		if (mediaThumbnail instanceof Array) {
			mediaThumbnail = getHighQThumbnailFromRenditions(mediaThumbnail);
		}
		return mediaThumbnail;
	}, [mediaDetail, thumbnailMedia]);

	const typeText = useMemo(() => {
		return getMediaTypeText(mediaDetail, mediaType, t);
	}, [mediaType, mediaDetail]);

	const onOpenShortcutMenu = useCallback(
		(e: React.MouseEvent<HTMLElement>, isMoreBtnClicked = false) => {
			if (isMiniLibrary) {
				return;
			}

			e.stopPropagation();
			e.preventDefault();

			setContextMenu(
				isMoreBtnClicked
					? e.currentTarget
					: {
							left: e.clientX + 2,
							top: e.clientY - 6,
					  }
			);
		},
		[isMiniLibrary]
	);

	const onContextMenuItemClick = (item: any) => {
		setContextMenu(null);
		switch (item.value) {
			case mediaActionKeys.openMediaInNewWindow:
				window.open(getInspectLink(mediaDetail, false, mediaType), '_blank');
				break;
			case mediaActionKeys.inspectCatalog:
				window.open(getInspectLink(mediaDetail, true), '_self');
				break;
			default:
				onMediaActionMenuClick?.(mediaDetail, item.value);
		}
	};

	const onMediaItemClick = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			if (isMiniLibrary) {
				e.preventDefault();
				onSelectMedia?.(mediaDetail);
			}

			if (mediaDetail?.type === mediaTypes.catalog) {
				e.preventDefault();
				const catalogSubId = mediaDetail?.id ?? '';
				onLibraryViewChange?.('catalogs', catalogSubId);
				dispatch(setSelectedCatalogId(catalogSubId === '' ? 'allCatalog' : catalogSubId));
				setTimeout(() => {
					window.open(getInspectLink(mediaDetail), '_self');
				});
			}

			onMediaItemClickProps?.(mediaDetail);
		},
		[isMiniLibrary, mediaDetail, onSelectMedia, onLibraryViewChange, dispatch, onMediaItemClickProps]
	);

	if (!mediaDetail) {
		return null;
	}

	return (
		<>
			<div className={mediaContainerBaseClassName} data-type={mediaDetail.type}>
				<div className={`${mediaContainerBaseClassName}__main`} onContextMenu={onOpenShortcutMenu}>
					{isMediaProcessing(restrictions ?? accountRestrictions, mediaDetail.appliedRestrictions) ? (
						<div className={`${mediaContainerBaseClassName}__main--processing`}>
							{t('COMMON_PROCESSING_VIDEO')}
						</div>
					) : (
						<img
							loading="lazy"
							className={
								!thumbnail ||
								thumbnail === no_thumbnail ||
								thumbnail.toString().toLowerCase().includes('nothumbnail')
									? `${mediaContainerBaseClassName}__main-no-thumbnail`
									: ''
							}
							src={thumbnail}
							width={'100%'}
						/>
					)}

					{thumbnail !== no_thumbnail && mediaDetail.id.startsWith(EDITED_MEDIA_ID_PREFIX) && (
						<IconButton
							className={`${mediaContainerBaseClassName}__adobe-express`}
							size="small"
							onClick={(event) => {
								onOpenShortcutMenu(event, true);
							}}
						>
							<AdobeExpressIcon />
						</IconButton>
					)}

					<span className={`${mediaContainerBaseClassName}__duration`}>
						{mediaDetail.duration && convertSecondsToTimeStamp(mediaDetail.duration)}
					</span>

					<div
						className={`${mediaContainerBaseClassName}__toolbar`}
						style={contextMenu || isSelected ? { visibility: 'visible' } : undefined}
					>
						<div className={`${mediaContainerBaseClassName}__toolbar--tools`}>
							<a
								onClick={onMediaItemClick}
								href={
									!isMiniLibrary && mediaDetail?.type !== mediaTypes.catalog
										? getInspectLink(mediaDetail, false, mediaType)
										: ''
								}
								className={`${mediaContainerBaseClassName}__toolbar--bigBtn`}
							>
								{isMiniLibrary && onSelectMedia ? t('MEDIA_LIBRARY_MEDIA_DISPLAY_SELECT_MEDIA') : ''}
							</a>

							{onSelectMedia && (
								<IconButton onClick={() => onSelectMedia?.(mediaDetail)}>
									{isSelected ? (
										<CheckBox htmlColor={'#fff'} />
									) : (
										<CheckBoxOutlineBlank htmlColor={'#fff'} />
									)}
								</IconButton>
							)}

							{!isMiniLibrary && (
								<div style={{ flex: 1 }}>
									<IconButton style={{ float: 'right' }} onClick={(e) => onOpenShortcutMenu(e, true)}>
										<MoreHorizOutlined htmlColor="#fff" />
									</IconButton>
								</div>
							)}
						</div>
					</div>
				</div>

				<div className={`${mediaContainerBaseClassName}__detail`}>
					<div className={`${mediaContainerBaseClassName}__type`}>
						{typeText === t('COMMON_MEDIA_TYPE_PLAYLIST') && <PlaylistPlayOutlined />}
						{typeText === t('COMMON_MEDIA_TYPE_INTERACT') && <Interact />}
						{typeText === t('COMMON_MEDIA_TYPE_IMAGE') && <ImageOutlined />}
						{typeText === t('COMMON_MEDIA_TYPE_VIDEO') && <OndemandVideoOutlined />}
						{typeText === t('COMMON_MEDIA_LIVE_EVENT') && <OndemandVideoOutlined />}

						{typeText && <span>{typeText}</span>}
					</div>

					<span className={`${mediaContainerBaseClassName}__timestamp`}>
						{dateToTimeElapsed(mediaDetail.created, t as any)}
					</span>
				</div>
				<span
					className={`${mediaContainerBaseClassName}__title`}
					title={mediaDetail.title || t('COMMON_NO_TITLE')}
				>
					{mediaDetail.title || t('COMMON_NO_TITLE')}
				</span>
			</div>

			{Boolean(contextMenu) && (
				<Menu
					open={Boolean(contextMenu)}
					getContentAnchorEl={null}
					onClose={() => setContextMenu(null)}
					anchorReference={contextMenu?.top !== undefined ? 'anchorPosition' : undefined}
					anchorPosition={contextMenu}
					anchorEl={contextMenu?.top !== undefined ? undefined : contextMenu}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
					//reuse same menu for ae shortcut button -> some glitch when close menu -> modified exit transition
					transitionDuration={{ appear: 200, enter: 200, exit: 0 }}
				>
					{(
						(ShortcutMenuItems as any)[
							mediaType === mediaNames.trashedMedias ? mediaType : mediaDetail.type ?? mediaType
						] ?? ShortcutMenuItems.unknown
					)?.map((item: any, i: number) => {
						if (
							item.value === mediaActionKeys.editWithAE ||
							item.value === mediaActionKeys.openAEProject ||
							item.value === mediaActionKeys.openAndReplaceAEProject
						) {
							if (!isAllowAEOption || isMobileView) {
								return null;
							}

							const isMediaCreatedByAEEditor = media.id.startsWith(EDITED_MEDIA_ID_PREFIX);

							if (isMediaCreatedByAEEditor && item.value === mediaActionKeys.editWithAE) {
								return null;
							}

							if (
								!isMediaCreatedByAEEditor &&
								(item.value === mediaActionKeys.openAEProject ||
									item.value === mediaActionKeys.openAndReplaceAEProject)
							) {
								return null;
							}
						}

						if (contextMenu?.classList?.contains(`${mediaContainerBaseClassName}__adobe-express`)) {
							if (
								item.value !== mediaActionKeys.openAEProject &&
								item.value !== mediaActionKeys.openAndReplaceAEProject
							) {
								return null;
							}
						}

						if (
							item.value === mediaActionKeys.editAccessCatalog ||
							item.value === mediaActionKeys.inspectCatalog ||
							item.value === mediaActionKeys.deleteMedia
						) {
							if (notAllowToShowEditCatalogAccess(media, catalogs, userId, userPermissions)) {
								return null;
							}
						}

						{
							return (
								<MenuItem key={i} onClick={() => onContextMenuItemClick(item)}>
									<ListItemIcon style={{ minWidth: '30px', ...(item.style ?? {}) }}>
										{item.icon}
									</ListItemIcon>
									<ListItemText primary={t(item.text)} />
								</MenuItem>
							);
						}
					})}
				</Menu>
			)}
		</>
	);
};

interface MediaActionProps {
	actionLabel?: string;
	onActionClick?: () => void;
}

export const MediaAction = ({ onActionClick, actionLabel }: MediaActionProps) => {
	return (
		<>
			<div className={mediaContainerBaseClassName}>
				<div className={joinClassNames(`${mediaContainerBaseClassName}__main`)} onClick={onActionClick}>
					<div className={`${mediaContainerBaseClassName}__main-shortcut`}>
						<div className={`${mediaContainerBaseClassName}__main-shortcut-flex-container`}>
							<AddCircleOutline htmlColor="#909CA5" fontSize="large" />
							{actionLabel && <div>{actionLabel}</div>}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
