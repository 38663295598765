import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as H from 'history';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Cookies from 'js-cookie';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { CloudUploadOutlined, Lock, PlaylistAddOutlined } from '@material-ui/icons';
import VideocamIcon from '@material-ui/icons/Videocam';

import ScreenParentContainer from '../../../reusable/ScreenParentContainer/ScreenParentContainer';
import BreadCrumb from '../../../layout/header/breadCrumb';
import {
	FLOWS,
	customScreenSizes,
	customScreenType,
	mediaActionKeys,
	mediaNames,
	mediaTypes,
	messageTypes,
	sortingOptionsDropDownItems,
} from '../../../constants/mediaConstants';
import { ReactComponent as Folder } from '../../../assets/svg/Folder.svg';
import { ReactComponent as Interact } from '../../../assets/svg/InteractiveProject.svg';
import { ReactComponent as LiveEvent } from '../../../assets/svg/LiveEvent.svg';
import { ReactComponent as ContentCut } from '../../../assets/svg/ContentCut.svg';

import { useFetchLibraryData } from '../../../hooks/screens/library/useFetchLibraryData';
import LibraryPagination from '../../../reusable/Pagination/LibraryPagination';
import { checkForAccessToChannels, checkPermissionGrantedFor } from '../../../services/componentReusableService';
import ColorHub from '../../../reusable/ColorHub/ColorHub';
import { setShowUploadMediasDialog } from '../../../actions/uploadingActions';
import { showMessage } from '../../../actions/globalActions';
import {
	addNewMedia,
	getMediaQueryDetails,
	moveMediaToBin,
	refreshCatalogs,
	restoreMedia,
	setPermissionInformationDialog,
} from '../../../actions/publishActions';
import { screenRoutes } from '../../../constants/routesPath';
import ScenarioCreationDialog from '../../Scenario/utils/ScenarioCreationDialog';
import { MediasDisplay } from '../../../reusable/MediaDisplay/MediasDisplay';
import { randomString } from '../../../services/stringHelperService';
import { DashboardUtils } from '../../DashboardScreen/DashboardUtils';
import { getMediaResourceUrl } from '../../../services/mediaDisplayService';
import useAEEditor from '../../../hooks/useAEEditor';
import { setScreenRecorderModalStatus } from '../../../actions/sessionActions';
import { updateAdobeExpressEditorModal } from '../../../actions/sessionActions';

import EditCatalogDialog from '../InspectScreen/InspectSections/EditCatalogDialog';
import { AddToPlaylistDialog } from '../InspectScreen/InspectSections/AddToPlaylistDialog';
import CreateNewPlaylistDialog from '../InspectScreen/InspectSections/CreateNewPlaylistDialog';
import AddToCatalogDialog from '../InspectScreen/InspectSections/AddToCatalogDialog';
import DeleteMediaDialog from '../DeleteMediaDialog';
import CreateLiveEventDialog from '../CreateLiveEventDialog';
import MediaLibrarySideBar from '../LibrarySidebar/MediaLibrarySideBar';
import { AdobeExpressUtils } from '../AdobeExpress/utils';

import SearchArea from './SearchArea';
import { HandlerType, ViewType } from './utils';

import './MediaLibrary.scss';

interface MediaLibraryProps {
	history: H.History;
	mediaType: string;
	mediaFilters?: any;
}

const MediaLibrary = ({
	history,
	mediaType,
	mediaFilters: mediaFiltersProps = {
		[mediaTypes.video]: true,
		[mediaTypes.livevideo]: true,
	},
}: MediaLibraryProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch() as any;

	const { userPermissions, defaultAccountId, accounts } = useSelector((state) => (state as any).session);
	const { ccEverywhere, qbrickStandardFlows } = useSelector((state) => (state as any).publish);

	const [libraryHeight, setLibraryHeight] = useState('100%');

	const breadCrumbRef = useRef<HTMLElement | null>(null);
	const toolbarRef = useRef<HTMLElement | null>(null);
	const advancedSearchAreaRef = useRef<HTMLElement | null>(null);
	const paginationRef = useRef<HTMLElement | null>(null);
	const showCreateCatalogDialogHandler = useRef<HandlerType | null>(null);
	const clearSearchRef = useRef<HandlerType | null>(null);

	const getShowCreateCatalogDialogHandler = (handler: HandlerType) =>
		(showCreateCatalogDialogHandler.current = handler);

	const [refreshMedias, setRefreshMedias] = useState(true);
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(50);
	const [sortType, setSortType] = useState(sortingOptionsDropDownItems[0]);
	const [selectedMedias, setSelectedMedias] = useState<any[]>([]);
	const [processingMedias, setProcessingMedias] = useState<any[]>([]);
	const [showActionDialog, setShowActionDialog] = useState('');
	const [saveFilterAsPlaylist, setSaveFilterAsPlaylist] = useState(false);
	const [uploadMenuAnchor, setUploadMenuAnchor] = useState<HTMLElement | null>(null);
	const [mediaFilters, setMediaFilters] = useState(mediaFiltersProps);
	const [currentMediaId, setCurrentMediaId] = useState('');
	const [searchQuery, setSearchQuery] = useState('');
	const [mediaView, setMediaView] = useState<ViewType>('grid');
	const [screenType, setScreenType] = useState(customScreenType.large);
	const [playlistPreviousSource, setPlaylistPreviousSource] = useState<string>('playlists');

	const getClearSearchHandler = (handler: HandlerType) => (clearSearchRef.current = handler);

	const setLoadingCallback = useCallback((value: boolean) => setLoading(value), []);

	const isPegacoderEnable = useMemo(() => {
		return qbrickStandardFlows.find((f: any) => f.id === FLOWS.qbrickStandard);
	}, [qbrickStandardFlows]);

	const { items, count, liveChannels, thumbnails, restrictions } = useFetchLibraryData({
		mediaType: mediaType,
		mediaFilters: mediaFilters,
		offset: page * rowsPerPage,
		limit: rowsPerPage,
		sortType: sortType.key,
		searchQuery,
		catalogId: currentMediaId,
		isReFetched: refreshMedias,
		loadChannels: true || refreshMedias,
		setLoading: setLoadingCallback,
	});

	const { uploadToPlatformCallback } = useAEEditor({
		afterJobFunc: () => {
			dispatch(updateAdobeExpressEditorModal(false));
		},
	});

	const refreshPage = useCallback(
		({
			openMediaInspectPage,
			redirectRoute,
			mediaName,
			mediaId,
			callback,
		}: {
			openMediaInspectPage: boolean;
			redirectRoute?: string;
			mediaName?: string;
			mediaId?: string;
			callback?: () => void;
		}) => {
			setTimeout(() => {
				dispatch(refreshCatalogs());
				if (!openMediaInspectPage) {
					setRefreshMedias((prev) => !prev);
					callback?.();
					return;
				}

				if (!mediaName || !mediaId) {
					return;
				}

				dispatch(getMediaQueryDetails(defaultAccountId, mediaId, mediaName)).then((data: any) => {
					if (!data || (data && data.message)) {
						dispatch(
							showMessage(t('MEDIA_LIBRARY_NOTIFICATION_REFRESH_TO_REVIEW_CONTENT'), messageTypes.info)
						);
						return;
					}
					redirectRoute && history.push(redirectRoute);
				});
			}, 2000);
		},
		[defaultAccountId, dispatch, history]
	);

	const onLibraryViewChange = useCallback((_type: string, subId?: string) => {
		setRowsPerPage(50);
		setPage(0);
		setProcessingMedias([]);
		setSelectedMedias([]);
		if (subId !== undefined) {
			setCurrentMediaId(subId);
		}
	}, []);

	const onRowsPerPageChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerPage(parseInt(e.target.value, 10));
		setPage(0);
	}, []);

	const onSelectMedia = useCallback((media: any) => {
		setSelectedMedias((prevSelectedMedias) =>
			(prevSelectedMedias.find((m) => m.id === media.id)
				? prevSelectedMedias.filter((m) => m.id !== media.id)
				: [...prevSelectedMedias, media]
			).filter(Boolean)
		);
	}, []);

	const onSelectAllMediaItemsPerPage = useCallback(() => {
		setSelectedMedias((prevSelectedMedias) => (items.length === prevSelectedMedias.length ? [] : items));
	}, [items]);

	const resetProcessingMedia = useCallback(() => {
		if (_.isEqual(selectedMedias, processingMedias)) {
			setSelectedMedias([]);
		}
		setProcessingMedias([]);
	}, [selectedMedias, processingMedias]);

	const onAssignMediasToCatalog = useCallback(() => {
		setShowActionDialog('');
		resetProcessingMedia();
	}, [resetProcessingMedia]);

	const onCopyMedia = useCallback(
		(mediaDetail: any) => {
			if (!mediaDetail?.id) {
				return;
			}

			const { id, type } = mediaDetail;
			let mediaName = mediaNames.medias;
			switch (type) {
				case mediaTypes.playlists:
					mediaName = mediaNames.playlists;
					break;
				case mediaTypes.scenario:
					mediaName = mediaNames.scenarios;
					break;
				case mediaTypes.catalog:
					mediaName = mediaNames.catalogs;
					break;
				default:
					mediaName === mediaNames.medias;
			}

			dispatch(getMediaQueryDetails(defaultAccountId, id, mediaName)).then((media: any) => {
				if ((media && media.message) || !media) {
					dispatch(
						showMessage(t('MEDIA_LIBRARY_NOTIFICATION_FAILED_TO_CREATE_COPY_OF_MEDIA'), messageTypes.error)
					);
					return;
				}

				let copyMedia = {
					...media,
					id: `${media.id ?? id}-${randomString()}-copy`,
					metadata: { ...(media.metadata ?? {}), title: `${media.metadata?.title ?? ''}-copy` },
				};

				dispatch(addNewMedia(defaultAccountId, copyMedia, mediaName)).then((res: any) => {
					if ((res && res.message) || !res) {
						dispatch(
							showMessage(
								t('MEDIA_LIBRARY_NOTIFICATION_FAILED_TO_CREATE_COPY_OF_MEDIA'),
								messageTypes.error
							)
						);
						return;
					}

					resetProcessingMedia();
					setShowActionDialog('');
					refreshPage({
						openMediaInspectPage: false,
						callback: () =>
							dispatch(
								showMessage(
									t('MEDIA_LIBRARY_NOTIFICATION_COPIED_MEDIA_SUCCESSFULLY'),
									messageTypes.success
								)
							),
					});
				});
			});
		},
		[defaultAccountId, dispatch, refreshPage, resetProcessingMedia, t]
	);

	const moveMediasToBin = useCallback(
		(mediasToMove: any[]) => {
			if (!mediasToMove?.length) {
				return;
			}

			let count = 0;
			let failedToMove = 0;
			mediasToMove.forEach((media) => {
				if (!media?.id) {
					return;
				}

				dispatch(moveMediaToBin({ accountId: defaultAccountId, mediaId: media.id }))
					.then((res: any) => {
						count++;
						if (!res || res.message) {
							failedToMove++;
						}

						if (count === mediasToMove.length) {
							refreshPage({
								openMediaInspectPage: false,
								callback: () => {
									setSelectedMedias((prevSelectedMedias) => {
										const newSelectedMedias = (prevSelectedMedias ?? []).filter(
											(s) => !mediasToMove.find((m) => m.id === s.id)
										);
										return [...newSelectedMedias];
									});
									setProcessingMedias([]);
									if (failedToMove > 0) {
										dispatch(
											showMessage(
												`${t(
													'MEDIA_LIBRARY_NOTIFICATION_MOVE_MEDIA_TO_BIN_FAILED'
												)} ${failedToMove} media(s)`,
												messageTypes.error
											)
										);
									}
									if (count > failedToMove) {
										dispatch(
											showMessage(
												`${count - failedToMove} ${t(
													'MEDIA_LIBRARY_NOTIFICATION_MOVE_MEDIA_TO_BIN_SUCCESSFULLY'
												)}`,
												messageTypes.success
											)
										);
									}
								},
							});
						}
					})
					.catch((error: any) =>
						dispatch(
							showMessage(
								`${t('MEDIA_LIBRARY_NOTIFICATION_MOVE_MEDIA_TO_BIN_FAILED')}. ${error}`,
								messageTypes.error
							)
						)
					);
			});
		},
		[defaultAccountId, dispatch, refreshPage, t]
	);

	const restoreMedias = useCallback(
		(mediasToRestore: any[]) => {
			if (!mediasToRestore?.length) {
				return;
			}

			let count = 0;
			let failedToRestore = 0;
			mediasToRestore.forEach((media) => {
				if (!media?.id) {
					return;
				}

				dispatch(restoreMedia({ accountId: defaultAccountId, mediaId: media.id })).then((res: any) => {
					count++;
					if (!res || res.message) {
						failedToRestore++;
					}

					if (count === mediasToRestore.length) {
						refreshPage({
							openMediaInspectPage: false,
							callback: () => {
								setSelectedMedias([]);
								setProcessingMedias([]);
								if (failedToRestore > 0) {
									dispatch(
										showMessage(
											`${t(
												'MEDIA_LIBRARY_NOTIFICATION_RESTORE_MEDIA_FAILED'
											)} ${failedToRestore} media(s)`
										),
										messageTypes.error
									);
								}
								if (count > failedToRestore) {
									dispatch(
										showMessage(
											`${count - failedToRestore} ${t(
												'MEDIA_LIBRARY_NOTIFICATION_RESTORE_MEDIA_SUCCESSFULLY'
											)}`,
											messageTypes.success
										)
									);
								}
							},
						});
					}
				});
			});
		},
		[defaultAccountId, dispatch, refreshPage, t]
	);

	const onMediaActionMenuClick = useCallback(
		(mediaDetail: any, action: string) => {
			if (action === mediaActionKeys.duplicateMedia) {
				onCopyMedia(mediaDetail);
			}
			if (action === mediaActionKeys.moveToBin) {
				moveMediasToBin([mediaDetail]);
			}
			if (action === mediaActionKeys.restore) {
				restoreMedias([mediaDetail]);
			}

			if (action === mediaActionKeys.editWithAE) {
				const dataUrl = getMediaResourceUrl(mediaDetail);
				createAdobeExpressDesign(dataUrl, mediaDetail.metadata?.title);
			}

			if (action === mediaActionKeys.openAEProject || action === mediaActionKeys.openAndReplaceAEProject) {
				const projectId = AdobeExpressUtils.getAdobeExpressProjectIdFromMediaId(mediaDetail.id);
				let replaceMediaContent =
					action === mediaActionKeys.openAndReplaceAEProject
						? { mediaId: mediaDetail.id, assetId: mediaDetail.asset.id }
						: undefined;
				projectId &&
					AdobeExpressUtils.editDesign({
						ccEverywhere,
						projectId,
						actionCallback: uploadToPlatformCallback,
						mediaName: mediaDetail.metadata?.title,
						replaceMediaContent,
					});
				dispatch(updateAdobeExpressEditorModal(true));
			}

			setProcessingMedias([mediaDetail]);
			setShowActionDialog(action);
		},
		[onCopyMedia, moveMediasToBin, restoreMedias, ccEverywhere]
	);

	const onMediaFilterChange = useCallback(
		(type: any) => {
			setMediaFilters((prevMediaFilters: any) => {
				const newMediaFilters = {
					...prevMediaFilters,
					[type]: !mediaFilters[type],
				};

				const totalSelectedFiler = Object.values(newMediaFilters).reduce((sum: number, cur: any) => {
					if (cur) {
						sum++;
					}
					return sum;
				}, 0);

				if (totalSelectedFiler === 0) {
					dispatch(
						showMessage(t('MEDIA_LIBRARY_NOTIFICATION_SELECT_AT_LEAST_ONE_FILTER'), messageTypes.info)
					);
					return prevMediaFilters;
				}

				return newMediaFilters;
			});
		},
		[dispatch, mediaFilters, t]
	);

	const onCreateNewPlaylist = useCallback(
		(playlistId: string) => {
			setShowActionDialog('');
			resetProcessingMedia();
			refreshPage({
				openMediaInspectPage: true,
				redirectRoute: `${screenRoutes.PUBLISH_PLAYLISTS}/${playlistId}/detail`,
				mediaName: mediaNames.playlists,
				mediaId: playlistId,
			});
		},
		[refreshPage, resetProcessingMedia]
	);

	const createLiveMedia = useCallback(
		(mediaId: string) => {
			refreshPage({
				openMediaInspectPage: true,
				redirectRoute: `${screenRoutes.PUBLISH}/medias/${mediaId}/detail`,
				mediaName: mediaNames.medias,
				mediaId: mediaId,
			});
		},
		[refreshPage]
	);

	const createLegacyLiveMedia = useCallback(
		(mediaId: string) => {
			refreshPage({
				openMediaInspectPage: true,
				redirectRoute: `${screenRoutes.PUBLISH}/medias/${mediaId}/detail`,
				mediaName: mediaNames.medias,
				mediaId: mediaId,
			});
		},
		[refreshPage]
	);

	const handleDeleteItems = useCallback(() => {
		resetProcessingMedia();
		setShowActionDialog('');
		refreshPage({ openMediaInspectPage: false });
	}, [refreshPage, resetProcessingMedia]);

	const onCreateScenario = useCallback(
		(scenarioId: string) => {
			setShowActionDialog('');
			refreshPage({
				openMediaInspectPage: true,
				redirectRoute: `${screenRoutes.PUBLISH_SCENARIOS}/${scenarioId}/detail`,
				mediaName: mediaNames.scenarios,
				mediaId: scenarioId,
			});
		},
		[refreshPage]
	);

	const handleSearch = useCallback((searchQuery: string) => {
		setSearchQuery(searchQuery);
		setRefreshMedias((prev) => !prev);
	}, []);

	const onSaveNewSearchFilter = useCallback(
		(filterId: string) => {
			history.push(screenRoutes.PUBLISH_MEDIA_LIBRARY + '/smartfilters/' + filterId);
		},
		[history]
	);

	const onSaveFilterAsPlaylistClick = useCallback(() => {
		setShowActionDialog(mediaActionKeys.createPlaylist);
		setSaveFilterAsPlaylist(true);
	}, []);

	const onToolbarActionClick = useCallback(
		(action: string) => {
			if (action === mediaActionKeys.restore) {
				restoreMedias(selectedMedias);
				return;
			}
			if (action === mediaActionKeys.moveToBin) {
				moveMediasToBin(selectedMedias);
				return;
			}
			setProcessingMedias(selectedMedias);
			setShowActionDialog(action);
		},
		[restoreMedias, selectedMedias, moveMediasToBin]
	);

	const setViewForLibrary = useCallback(
		(view: ViewType) => {
			setMediaView(view);
			const userName = accounts[0] ? accounts[0]?.customerId : '';
			Cookies.set('filter_' + userName + '_view', view);
		},
		[accounts]
	);

	const setSortTypeForLibrary = useCallback(
		(newSortType: any) => {
			setSortType(newSortType);
			const userName = accounts[0] ? accounts[0]?.customerId : '';
			Cookies.set('filter_' + userName + '_sortType', newSortType.key);
		},
		[accounts]
	);

	const getStateFromCookies = useCallback(() => {
		const userName = accounts[0] ? accounts[0]?.customerId : '';
		const cookieSortType = Cookies.get('filter_' + userName + '_sortType');
		const cookieView = Cookies.get('filter_' + userName + '_view');

		if (cookieView && cookieView !== mediaView) {
			setMediaView(cookieView as ViewType);
		}
		if (cookieSortType && cookieSortType !== sortType.key) {
			setSortType(
				sortingOptionsDropDownItems.find((s) => s.key === cookieSortType) ?? sortingOptionsDropDownItems[0]
			);
		}
	}, [accounts, mediaView, sortType.key]);

	const getLibraryHeight = useCallback(() => {
		const header = document.querySelector('.header-container')?.getBoundingClientRect()?.height ?? 0;
		const breadCrumbHeight = breadCrumbRef?.current ? breadCrumbRef.current.getBoundingClientRect().height : 0;
		const toolbarHeight = toolbarRef?.current ? toolbarRef.current.getBoundingClientRect().height : 0;
		const advancedSearchAreaHeight = advancedSearchAreaRef?.current
			? advancedSearchAreaRef.current.getBoundingClientRect().height
			: 0;
		const paginationHeight = paginationRef?.current ? paginationRef.current.getBoundingClientRect().height : 0;

		const newHeight =
			Math.round(
				window.innerHeight -
					breadCrumbHeight -
					toolbarHeight -
					advancedSearchAreaHeight -
					paginationHeight -
					header
			) + 'px';

		if (newHeight !== libraryHeight) {
			setLibraryHeight(newHeight);
		}
	}, [libraryHeight]);

	const getScreenType = useCallback(() => {
		let newScreenType = customScreenType.large;
		if (window.innerWidth > customScreenSizes.medium) {
			newScreenType = customScreenType.large;
		} else if (window.innerWidth <= customScreenSizes.medium && window.innerWidth > customScreenSizes.tablet) {
			newScreenType = customScreenType.medium;
		} else if (window.innerWidth <= customScreenSizes.tablet && window.innerWidth > customScreenSizes.small) {
			newScreenType = customScreenType.tablet;
		} else if (window.innerWidth <= customScreenSizes.small && window.innerWidth > customScreenSizes.mobile) {
			newScreenType = customScreenType.small;
		} else if (window.innerWidth <= customScreenSizes.mobile) {
			newScreenType = customScreenType.mobile;
		}

		setScreenType(newScreenType);
		if (newScreenType === customScreenType.small || newScreenType === customScreenType.mobile) {
			setMediaView('list');
		}
	}, []);

	const onScreenResize = useCallback(() => {
		getScreenType();
		getLibraryHeight();
	}, [getLibraryHeight, getScreenType]);

	const createAdobeExpressDesign = useCallback(
		(dataUrl?: string, mediaName?: string) => {
			AdobeExpressUtils.createNewProject({
				ccEverywhere,
				actionCallback: uploadToPlatformCallback,
				dataUrl,
				mediaName,
			});
			dispatch(updateAdobeExpressEditorModal(true));
		},
		[ccEverywhere, dispatch, uploadToPlatformCallback]
	);

	const handleScreenRecorderClick = useCallback(() => {
		if (!isPegacoderEnable) {
			dispatch(setPermissionInformationDialog(DashboardUtils.RecentMediaTypes.record));
			return;
		}
		dispatch(setScreenRecorderModalStatus(true));
		setUploadMenuAnchor(null);
	}, [dispatch, isPegacoderEnable]);

	useEffect(() => {
		getStateFromCookies();
	}, [getStateFromCookies]);

	useEffect(() => {
		onScreenResize();
	}, [loading, onScreenResize]);

	useEffect(() => {
		onScreenResize();
		window.addEventListener('resize', onScreenResize);

		return () => window.removeEventListener('resize', onScreenResize);
	}, [onScreenResize]);

	useEffect(() => {
		const locationArray = window.location.hash.split('/');
		const indexOfType = locationArray.findIndex((i) => i === mediaType);
		if (indexOfType > -1) {
			setCurrentMediaId(locationArray[indexOfType + 1]);
		}
	}, [mediaType]);

	return (
		<ScreenParentContainer noSizeCalculation>
			<div className="media-library-screen">
				{!(screenType === customScreenType.small || screenType === customScreenType.mobile) && (
					<MediaLibrarySideBar
						mediaType={mediaType ?? mediaNames.medias}
						clearSearch={clearSearchRef.current}
						history={history}
						getShowCreateCatalogDialogHandler={getShowCreateCatalogDialogHandler}
						onLibraryViewChange={onLibraryViewChange}
					/>
				)}

				<div className="media-library">
					<BreadCrumb
						history={history}
						breadCrumbRef={(ref: HTMLElement | null) => (breadCrumbRef.current = ref)}
						onLibraryViewChange={onLibraryViewChange}
					/>

					<SearchArea
						mediaType={mediaType}
						history={history}
						toolbarRef={(ref: HTMLElement | null) => (toolbarRef.current = ref)}
						screenType={screenType}
						view={mediaView ?? 'grid'}
						isMobileView={screenType === customScreenType.small || screenType === customScreenType.mobile}
						sortType={sortType.key}
						handleSortTypeChange={setSortTypeForLibrary}
						isSelectAll={selectedMedias.length === items.length && items.length > 0}
						selectedMedias={selectedMedias}
						onSelectAllMediaItemsPerPage={onSelectAllMediaItemsPerPage}
						onToolbarActionClick={onToolbarActionClick}
						showUploadDropDownMenu={(e) => setUploadMenuAnchor(e.currentTarget as any)}
						onMediaFilterChange={onMediaFilterChange}
						mediaFilters={mediaFilters}
						advancedSearchAreaRef={(ref: HTMLElement | null) => (advancedSearchAreaRef.current = ref)}
						onSearch={handleSearch}
						onSaveNewSearchFilter={onSaveNewSearchFilter}
						onSaveFilterAsPlaylistClick={onSaveFilterAsPlaylistClick}
						onViewChange={setViewForLibrary}
						getClearSearchHandler={getClearSearchHandler}
					/>

					<MediasDisplay
						classNames={'library-main-display'}
						thumbnails={thumbnails}
						screenType={screenType}
						loading={loading}
						view={mediaView ?? 'grid'}
						style={{ maxHeight: libraryHeight, height: libraryHeight }}
						medias={items}
						mediaType={mediaType}
						onSelectMedia={onSelectMedia}
						selectedMedias={selectedMedias}
						onMediaActionMenuClick={onMediaActionMenuClick}
						showCreateScenarioBox={() => setShowActionDialog(mediaActionKeys.createInteractProject)}
						showUploadMediaDialogBox={() => dispatch(setShowUploadMediasDialog(true))}
						onSelectAllMediaItemsPerPage={onSelectAllMediaItemsPerPage}
						isSelectAll={selectedMedias.length === items.length && items.length > 0}
						onLibraryViewChange={onLibraryViewChange}
						searchQuery={searchQuery}
						restrictions={restrictions}
					/>

					{!loading && (
						<LibraryPagination
							count={count}
							page={page}
							rowsPerPage={rowsPerPage}
							onPageChange={(_e, _offset) => setPage(_offset)}
							onRowsPerPageChange={onRowsPerPageChange}
							paginationRef={(ref: HTMLElement | null) => (paginationRef.current = ref)}
						/>
					)}
				</div>
			</div>

			<Menu
				anchorEl={uploadMenuAnchor}
				onClose={() => setUploadMenuAnchor(null)}
				open={Boolean(uploadMenuAnchor)}
				getContentAnchorEl={null}
				disableAutoFocusItem
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			>
				<MenuItem
					className="media-library__item"
					onClick={() => {
						dispatch(setShowUploadMediasDialog(true));
						setUploadMenuAnchor(null);
					}}
					key={mediaActionKeys.uploadMedia}
				>
					<ListItemIcon style={{ minWidth: '30px' }}>
						<CloudUploadOutlined />
					</ListItemIcon>
					<ListItemText primary={t('COMMON_UPLOAD')} />
				</MenuItem>

				<MenuItem
					onClick={() => {
						if (userPermissions && !checkPermissionGrantedFor(userPermissions, 'adobeExpress')) {
							dispatch(setPermissionInformationDialog(DashboardUtils.RecentMediaTypes.edits));
							return;
						}
						createAdobeExpressDesign();
						setUploadMenuAnchor(null);
					}}
					key={mediaActionKeys.editVideos}
					className="media-library__item"
				>
					{userPermissions && !checkPermissionGrantedFor(userPermissions, 'adobeExpress') && (
						<div className={`media-library__item--locked`}>
							<Lock />
						</div>
					)}
					<ListItemIcon style={{ minWidth: '30px' }}>
						<ColorHub
							component={<ContentCut width={'18px'} height={'18px'} />}
							htmlColor="rgba(0, 0, 0, 0.54)"
						/>
					</ListItemIcon>
					<ListItemText primary={t('COMMON_EDIT')} />
				</MenuItem>

				<MenuItem
					className="media-library__item"
					onClick={handleScreenRecorderClick}
					key={mediaActionKeys.screenRecorder}
				>
					{!isPegacoderEnable && (
						<div className={`media-library__item--locked`}>
							<Lock />
						</div>
					)}
					<ListItemIcon style={{ minWidth: '30px' }}>
						<ColorHub component={<VideocamIcon />} htmlColor="rgba(0, 0, 0, 0.54)" />
					</ListItemIcon>
					<ListItemText primary={t('COMMON_CREATE_SCREEN_RECORDER')} />
				</MenuItem>

				{!(screenType === customScreenType.small || screenType === customScreenType.mobile) && (
					<MenuItem
						className="media-library__item"
						onClick={() => {
							showCreateCatalogDialogHandler?.current && showCreateCatalogDialogHandler.current();
							setUploadMenuAnchor(null);
						}}
						key={mediaActionKeys.createCatalog}
					>
						<ListItemIcon style={{ minWidth: '30px' }}>
							<ColorHub component={<Folder />} htmlColor="rgba(0, 0, 0, 0.54)" />
						</ListItemIcon>
						<ListItemText primary={t('COMMON_MEDIA_TYPE_CATALOG')} />
					</MenuItem>
				)}

				<MenuItem
					className="media-library__item"
					onClick={() => {
						if (userPermissions && !checkPermissionGrantedFor(userPermissions, 'scenarios')) {
							dispatch(setPermissionInformationDialog(DashboardUtils.RecentMediaTypes.interact));
							return;
						}
						setShowActionDialog(mediaActionKeys.createInteractProject);
						setUploadMenuAnchor(null);
					}}
					key={mediaActionKeys.createInteractProject}
				>
					{userPermissions && !checkPermissionGrantedFor(userPermissions, 'scenarios') && (
						<div className={`media-library__item--locked`}>
							<Lock />
						</div>
					)}
					<ListItemIcon style={{ minWidth: '30px' }}>
						<ColorHub component={<Interact />} htmlColor="rgba(0, 0, 0, 0.54)" />
					</ListItemIcon>
					<ListItemText primary={t('MEDIA_LIBRARY_NEW_MENU_INTERACTIVE_VIDEO')} />
				</MenuItem>

				<MenuItem
					className="media-library__item"
					onClick={() => {
						if (
							!(
								userPermissions &&
								(liveChannels ?? []).length > 0 &&
								(checkForAccessToChannels(userPermissions) ||
									checkPermissionGrantedFor(userPermissions, 'legacyLive'))
							)
						) {
							dispatch(setPermissionInformationDialog(DashboardUtils.RecentMediaTypes.live));
							return;
						}
						setShowActionDialog(mediaActionKeys.createLiveEvent);
						setUploadMenuAnchor(null);
					}}
					key={mediaActionKeys.createLiveEvent}
				>
					{!(
						userPermissions &&
						(liveChannels ?? []).length > 0 &&
						(checkForAccessToChannels(userPermissions) ||
							checkPermissionGrantedFor(userPermissions, 'legacyLive'))
					) && (
						<div className={`media-library__item--locked`}>
							<Lock />
						</div>
					)}
					<ListItemIcon style={{ minWidth: '30px' }}>
						<ColorHub component={<LiveEvent />} htmlColor="rgba(0, 0, 0, 0.54)" />
					</ListItemIcon>
					<ListItemText primary={t('COMMON_MEDIA_LIVE_EVENT')} />
				</MenuItem>

				<MenuItem
					className="media-library__item"
					onClick={() => {
						setShowActionDialog(mediaActionKeys.createPlaylist);
						setUploadMenuAnchor(null);
					}}
					key={mediaActionKeys.createPlaylist}
				>
					<ListItemIcon style={{ minWidth: '30px' }}>
						<PlaylistAddOutlined />
					</ListItemIcon>
					<ListItemText primary={t('COMMON_MEDIA_TYPE_PLAYLIST')} />
				</MenuItem>
			</Menu>

			<AddToPlaylistDialog
				open={showActionDialog === mediaActionKeys.addToPlaylist}
				showCreateNewPlaylist={() => {
					setPlaylistPreviousSource('publish');
					setShowActionDialog(mediaActionKeys.createPlaylist);
				}}
				mediasToAssign={processingMedias}
				onClose={() => setShowActionDialog('')}
				onAssignMediaToPlaylist={() => {
					setShowActionDialog('');
					resetProcessingMedia();
				}}
			/>

			<CreateNewPlaylistDialog
				open={showActionDialog === mediaActionKeys.createPlaylist}
				previousSource={playlistPreviousSource}
				onClose={() => {
					setShowActionDialog('');
					setPlaylistPreviousSource('playlists');
				}}
				onCreateNewPlaylist={onCreateNewPlaylist}
				mediasToAssign={processingMedias}
				saveFilterAsPlaylist={saveFilterAsPlaylist}
				searchQuery={searchQuery}
				backToThePreviousDialogBoxIfSpecified={() => setShowActionDialog(mediaActionKeys.addToPlaylist)}
			/>

			<AddToCatalogDialog
				open={showActionDialog === mediaActionKeys.addToCatalog}
				onClose={() => setShowActionDialog('')}
				onAssignMediasToCatalog={onAssignMediasToCatalog}
				mediasToAssign={processingMedias}
			/>

			<EditCatalogDialog
				open={showActionDialog === mediaActionKeys.editAccessCatalog}
				onClose={() => setShowActionDialog('')}
				editingCatalog={processingMedias[0]}
			/>

			<DeleteMediaDialog
				open={showActionDialog === mediaActionKeys.deleteMedia || showActionDialog === mediaActionKeys.emptyBin}
				deleteType={showActionDialog}
				onClose={() => setShowActionDialog('')}
				deletingMedias={processingMedias}
				handleDeleteItems={handleDeleteItems}
			/>

			<CreateLiveEventDialog
				liveChannels={liveChannels}
				open={showActionDialog === mediaActionKeys.createLiveEvent}
				hideCreateLiveEventDialogBox={() => setShowActionDialog('')}
				createLiveMedia={createLiveMedia}
				createLegacyLiveMedia={createLegacyLiveMedia}
			/>

			<ScenarioCreationDialog
				open={showActionDialog === mediaActionKeys.createInteractProject}
				onClose={() => setShowActionDialog('')}
				onCreateScenario={onCreateScenario}
			/>
		</ScreenParentContainer>
	);
};

export default MediaLibrary;
